@import 'application';

@font-face {
  font-family: 'Computer Modern Sans';
  src: url('../fonts/Sans/cmunss.eot');
  src:
    url('../fonts/Sans/cmunss.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Sans/cmunss.woff') format('woff'),
    url('../fonts/Sans/cmunss.ttf') format('truetype'),
    url('../fonts/Sans/cmunss.svg#cmunss') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Computer Modern Sans';
  src: url('../fonts/Sans/cmunsx.eot');
  src:
    url('../fonts/Sans/cmunsx.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Sans/cmunsx.woff') format('woff'),
    url('../fonts/Sans/cmunsx.ttf') format('truetype'),
    url('../fonts/Sans/cmunsx.svg#cmunsx') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Computer Modern Sans';
  src: url('../fonts/Sans/cmunsi.eot');
  src:
    url('../fonts/Sans/cmunsi.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Sans/cmunsi.woff') format('woff'),
    url('../fonts/Sans/cmunsi.ttf') format('truetype'),
    url('../fonts/Sans/cmunsi.svg#cmunsi') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Computer Modern Sans';
  src: url('../fonts/Sans/cmunso.eot');
  src:
    url('../fonts/Sans/cmunso.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Sans/cmunso.woff') format('woff'),
    url('../fonts/Sans/cmunso.ttf') format('truetype'),
    url('../fonts/Sans/cmunso.svg#cmunso') format('svg');
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: 'Computer Modern Sans', mastodon-font-sans-serif, sans-serif;
}
